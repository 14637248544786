<template>
    <fragment>
        <van-field :border="false"
                   :colon="false"
                   :value="value"
                   clearable
                   readonly
                   v-bind="$attrs"
                   @click-input.stop="showKeyboard = true"
        >
            <template v-if="$slots.label" #label>
                <slot name="label"></slot>
            </template>
        </van-field>
        <van-number-keyboard v-model="keyValue"
                             :extra-key="extraKey"
                             :show="showKeyboard"
                             :z-index="2"
                             close-button-text="完成"
                             @blur="showKeyboard = false"
                             @delete="onDelete"
                             @input="onInput"
        />
    </fragment>
</template>

<script>
    import {Fragment} from 'vue-fragment';

    export default {
        name: "MyDigitInput",
        components: {Fragment},
        props: {
            value: [String, Number],
            extraKey: String,
        },
        // 定义双向绑定
        model: {
            prop: 'value',
            event: 'input'
        },
        watch: {
            value() {
                this.setInputValue();
            }
        },
        data() {
            return {
                showKeyboard: false,
                keyValue: '',
            }
        },
        mounted() {
            this.setInputValue();
        },
        methods: {
            setInputValue() {
                this.keyValue = this.value || '';
            },
            // 输入事件
            onInput() {
                // 必须得在dom更新后，执行同步操作，否则会有延迟
                this.$nextTick(() => {
                    this.$emit('input', this.keyValue);
                });
            },
            // 删除事件
            onDelete() {
                this.onInput();
            },
        }
    }
</script>

<style scoped>

</style>